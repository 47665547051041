<template>
  <AView
    :value="value"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <LeadFormVue class="pa-4" v-model="form"></LeadFormVue>
    </template>
  </AView>
</template>

<script>
import LeadFormVue from "../../../../../components/wad/molecules/sales/LeadForm.vue";

import AView from "../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  validations: {
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },
  components: {
    LeadFormVue,
    AView,
  },
  data: () => ({
    form: {},
  }),

  computed: {},

  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
  },
};
</script>