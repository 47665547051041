<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <!-- Lead Name -->
    <HelpFormInputVue
      dark
      v-model="form.name"
      :label="TEXT.LEAD_NAME_LABEL_TEXT"
      :helpText="TEXT.LEAD_NAME_HELP_TEXT"
      required
    ></HelpFormInputVue>

    <!-- Lead Probability -->
    <SelectorLeadProbabilityVue
      dark
      v-model="form.probability"
      :label="TEXT.LEAD_PROBABILITY_LABEL_TEXT"
      :helpText="TEXT.LEAD_PROBABILITY_HELP_TEXT"
    ></SelectorLeadProbabilityVue>

    <!-- Lead Details -->
    <HelpFormInputVue
      dark
      textarea
      v-model="form.description"
      :label="TEXT.LEAD_INFORMATION_LABEL_TEXT"
      :helpText="TEXT.LEAD_INFORMATION_HELP_TEXT"
    ></HelpFormInputVue>

    <!-- Attached Files -->
    <!-- <FileInputVue
      dark
      multiple
      v-model="form.files"
      :label="TEXT.LEAD_ATTACHMENTS_LABEL_TEXT"
      :helpText="TEXT.LEAD_ATTACHMENTS_HELP_TEXT"
    ></FileInputVue> -->

    <FormSectionVue
      :label="'SERVICES'"
      class="mt-0"
      :icon="'mdi-briefcase'"
    ></FormSectionVue>

    <SelectorServicesVue></SelectorServicesVue>

    <FormSectionVue :label="'CUSTOMER'" :icon="'mdi-account'"></FormSectionVue>

    <SelectorCustomerVue
      class="transparent"
      v-model="form.customers"
    ></SelectorCustomerVue>

    <VueFileAgent
      :multiple="true"
      :deletable="true"
      :uploadUrl="'uploadUrl'"
      v-model="form.files"
    ></VueFileAgent>

    <!-- :uploadUrl="getUploadURL('PRIVATE')" -->
    <!-- :crossOrigin="'anonymous'" -->
  </v-form>
</template>


<script>
import { LEADS_TEXT } from "../../../constants/TEXT/Leads.text";
import FormSectionVue from "../../atoms/common/FormSection.vue";
// import FileInputVue from "../../atoms/common/inputs/FileInput.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";
import SelectorCustomerVue from "../../atoms/common/autocompletes/SelectorCustomer.vue";
import SelectorLeadProbabilityVue from "../../atoms/common/autocompletes/SelectorLeadProbability.vue";
import SelectorServicesVue from "../../atoms/common/autocompletes/SelectorServices.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //DEFAULT
      TEXT: LEADS_TEXT.NEW_LEAD_FORM,

      // Form
      pValid: this.valid,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: null,
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      customerTabs: undefined,

      form: {
        name: "",
        email: "",
        description: "",
        newCompany: {},
        company: {},
        files:[]
      },
    };
  },
  components: {
    HelpFormInputVue,
    FormSectionVue,
    SelectorServicesVue,
    SelectorCustomerVue,
    SelectorLeadProbabilityVue,
    // FileInputVue,
  },

  methods: {
    validate() {
      console.log("this.customerTabs: ", this.customerTabs);

      return (
        this.$refs.form.validate() &
        +(this.customerTabs === 0 ? this.$refs.customerForm.validate() : true)
      );
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.customerForm.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.$refs.customerForm.resetValidation();
    },
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>