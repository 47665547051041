<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :items="items"
    item-text="name"
    item-value="code"
    :label="label"
    dark
    clearable
    cache-items
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
            mdi-help
          </v-icon>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </template>
  </v-select>
</template>


<script>
import { LEAD_PROBABILITY } from '../../../../constants/defaults/Leads';
export default {
  props: {
    value: {  type: String },
    label: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: Object.values(LEAD_PROBABILITY)
    };
  },
};
</script>